import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo} from '../../assets/images/Swilo-logo-full.svg'
import '../../assets/css/navbar.css';
import { ReactComponent as PipeIcon } from '../../assets/images/line.svg';
import { ReactComponent as Person } from '../../assets/images/person.svg';
import { ReactComponent as HamburgerMenu } from '../../assets/images/Hamburger.svg'
import { ReactComponent as Disconnect } from '../../assets/images/disconnect.svg'
import { ReactComponent as ExitIcon} from '../../assets/images/exit.svg';
import { ReactComponent as HistoryIcon} from '../../assets/images/History.svg'
import {ReactComponent as ChatIcon} from '../../assets/images/Chat.svg'
import ShoppingCartIcon from "../cart/shopping-cart";
import {useCart} from '../../contexts/cartcontext';
import customAxios from "../../api/customAxios";

export default function Navbar({refetch , setRefetch , showFixedNavbar}) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [showSidebar, setShowSidebar] = useState(false); // State to control sidebar visibility
  const [userType , setUserType] = useState(null);
  const [logoutTriggered, setLogoutTriggered] = useState(false); // State to trigger component refresh

  const navigate = useNavigate();
  const { cartItems , removeAllFromCart } = useCart();
  
  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('user');
    removeAllFromCart();
    setUsername('');
    navigate("/");
    setLogoutTriggered(true);
    setRefetch(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
          const response = await customAxios.get("/auth/getUserByToken", {
            headers: {
              Authorization: user.accessToken,
            },
          });
          setUsername(response.data.username);
          setUserType(response.data.userType);
          setLoggedIn(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [logoutTriggered , refetch]);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
    <div className={`navbar regular-navbar`} id="navbar">
      <div className="first-nav">
        <div className="left-nav">
        </div>
        {loggedIn ? (
          <div className="account">
            <PipeIcon className="PipeIcon" />
            {userType === 'Pro' ? (
              <Link to="/dashboard" className="account">
                <Person className="PersonIcon" />
                <p>{username}</p>
              </Link>
            ) :
              <div className="account">
                <Person className="PersonIcon" />
                <p>{username}</p>
              </div>
            }
            
            
            <Link to='/history' className="account" aria-label="History">
              <HistoryIcon className="history" />
            </Link>
            <Disconnect className="disconnect" onClick={handleLogout}/> 
          </div>
        ) : (
          <Link to='/form' className="account">
            <PipeIcon className="PipeIcon" />
            <Person className="PersonIcon" />
            <p>Mon Compte</p>
          </Link>
        )}
      </div>
      <div className="second-nav">
        <div className="second-left">
          <HamburgerMenu className="hamburger-menu" onClick={toggleSidebar}/> {/* Clicking on the hamburger menu toggles the sidebar */}
          <Link to="/" aria-label="Home"><Logo className="Logo"/></Link>
          <div className={`navbar-list ${showSidebar ? 'show' : ''}`}> {/* Conditional class to show/hide sidebar */}
            <div className="sidebar-top">
              <Link to="/" aria-label="Home"><Logo className="Logo"/></Link>
              <ExitIcon className="exit" onClick={toggleSidebar}/>
            </div>
            
            <Link to="/" onClick={toggleSidebar}><p>Acceuil</p></Link>
            <Link to="/pro" onClick={toggleSidebar}><p>Trouver un WILO</p></Link>
            <Link to="/services" onClick={toggleSidebar}><p>Services</p></Link>
            <Link to="/produits" onClick={toggleSidebar}><p>Boutique</p></Link>
            <Link to="/promos" onClick={toggleSidebar}><p>Promos</p></Link>
          </div>
        </div>
        <div className="second-right">
          {loggedIn && ( // Render ChatIcon only if logged in
            <div className="shopping-cart">
              <Link to="/messages" aria-label="Chat">
                <ChatIcon className="ChatIcon"/>
              </Link>
            </div>
          )}
          <div className="shopping-cart">
            <Link to="/cart" aria-label="Cart">
              <ShoppingCartIcon cartItems={cartItems} />
            </Link>
          </div>
          <div className="container">
            
          </div>
        </div>
      </div>
    </div>
    {showFixedNavbar && (<div className={`navbar fixed-navbar`} id="navbar">
      <div className="second-nav">
        <div className="second-left">
          <HamburgerMenu className="hamburger-menu" onClick={toggleSidebar}/> {/* Clicking on the hamburger menu toggles the sidebar */}
          <Link to="/"><Logo className="Logo"/></Link>
          <div className={`navbar-list ${showSidebar ? 'show' : ''}`}> {/* Conditional class to show/hide sidebar */}
            <div className="sidebar-top">
              <Link to="/"><Logo className="Logo"/></Link>
              <ExitIcon className="exit" onClick={toggleSidebar}/>
            </div>
            
            <Link to="/" onClick={toggleSidebar}><p>Acceuil</p></Link>
            <Link to="/pro" onClick={toggleSidebar}><p>Trouver un WILO</p></Link>
            <Link to="/services" onClick={toggleSidebar}><p>Services</p></Link>
            <Link to="/produits" onClick={toggleSidebar}><p>Boutique</p></Link>
            <Link to="/promos" onClick={toggleSidebar}><p>Promos</p></Link>
          </div>
        </div>
        <div className="second-right">
          {loggedIn && ( // Render ChatIcon only if logged in
            <div className="shopping-cart">
              <Link to="/messages" aria-label="Chat">
                <ChatIcon className="ChatIcon"/>
              </Link>
            </div>
          )}
          <div className="shopping-cart">
            <Link to="/cart">
              <ShoppingCartIcon cartItems={cartItems} />
            </Link>
          </div>
         
        </div>
      </div>
    </div>)}
    </>
  );
}
