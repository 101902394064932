import React, { createContext, useContext, useEffect, useState } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const loadCartItemsFromLocalStorage = () => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart && JSON.parse(storedCart).length > 0) {
      const parsedCart = JSON.parse(storedCart);
      setCartItems(parsedCart); // Set cartItems with parsed data
    }
  };
  
  useEffect(() => {
    loadCartItemsFromLocalStorage();
  }, []);

  // Update local storage whenever cartItems change
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    const existingItemIndex = cartItems.findIndex(cartItem => cartItem.productId === item.productId);
    if (existingItemIndex !== -1) {
      // Item already exists in cart, update quantity
      const updatedCart = [...cartItems];
      updatedCart[existingItemIndex] = {
        ...updatedCart[existingItemIndex],
        quantity: updatedCart[existingItemIndex].quantity + item.quantity
      };
      setCartItems(updatedCart);
    } else {
      // Item does not exist in cart, add it
      setCartItems([...cartItems, item]);
    }
  };

  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter(item => item.productId !== productId);
    setCartItems(updatedCart);
  };

  const logCart = () => {
    console.log("Cart Items:", cartItems);
  };

  const updateCartItemQuantity = (productId, newQuantity) => {
    const updatedCart = cartItems.map(item => {
      if (item.productId === productId) {
        return {
          ...item,
          quantity: newQuantity
        };
      }
      return item;
    });
    setCartItems(updatedCart);
  };

  const removeAllFromCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, logCart, removeFromCart, updateCartItemQuantity , removeAllFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
