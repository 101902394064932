import React from "react";
import "../../assets/css/LoadingSpinner.css"; // Import CSS file for styling

export default function LoadingSpinner(props) {
  return (
    <div className={`loading-spinner-container ${props.type}`}>
      <div className="loading-spinner"></div>
    </div>
  );
};


