import './App.css';
import './assets/css/form.css'
import './assets/css/popup.css'
import './assets/css/cart.css'
import './assets/css/filter.css'
import './assets/css/landing.css'
import './assets/css/top.css'
import './assets/css/pro.css'
import './assets/css/dashboard.css'
import './assets/css/popup.css'
import './assets/css/commande.css'
import './assets/css/payment.css'

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingSpinner from './components/spinner/loadingSpinner';
import NavbarSwitcher from './components/landing/navbarSwitcher';
import customAxios from './api/customAxios';
import { CartProvider } from './contexts/cartcontext'; // Import CartProvider
const Landing = lazy(() => import('./components/landing/landing'));
const Form = lazy(() => import('./components/form/form'));
const ServicePage = lazy(() => import('./components/services/servicePage'));
const ProductPage = lazy(() => import('./components/produits/productPage'));
const History = lazy(() => import('./components/historique/historique'));
const Commande = lazy(() => import('./components/commande/commande'));
const Dashboard = lazy(() => import('./components/dashboard/dashboard'));
const Pro = lazy(() => import('./components/pro/pro'));
const ProProfile = lazy(() => import('./components/pro/pro-profile'));
const Postuler = lazy(() => import('./components/postuler/postuler'));
const Merci = lazy(() => import('./components/merci/merci'));
const CartPage = lazy(() => import('./components/cart/cart-page'));
const PromoPage = lazy(() => import('./components/promos/promos'));
const ProductInfo = lazy(() => import('./components/produits/product-info'));
const Confirmation = lazy(() => import('./components/cart/confirmation'));
const Failed = lazy(() => import('./components/payment/failed/failed'));
const Terms = lazy(() => import('./components/terms/terms'));
const Privacy = lazy(() => import('./components/privacy-policy/privacy'));
const MerciProduit = lazy(() => import('./components/merci/merci-produit'));
const Unauthorized = lazy(() => import('./components/unauthorized/unauthorized'));
const NotFound = lazy(() => import('./components/not-found/not-found'));
const Payment = lazy(() => import('./components/payment/payment'));
const Footer = lazy(() => import('./components/landing/footer'));
const Messages = lazy(() => import('./components/dashboard/messages/messages'));
const Candidature = lazy(()=> import('./components/postuler/candidature'));

function App() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [showFooter, setShowFooter] = useState(false); // State to control footer rendering
  const [ isPro , setIsPro ] = useState(false)
  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      setLoading(true);

      const storedUser = JSON.parse(localStorage.getItem('user'));
      
      if (storedUser && storedUser?.accessToken) {
        const userData = await customAxios.get(`auth/getUserByToken/`, {
          headers: {
            Authorization: `${storedUser?.accessToken}`
          }
        });     
        setUser(userData);
        if (userData.data.userType === "Pro"){
          setIsPro(true)
        }
        setLoading(false);
      } else {
        setLoading(false);
        setUser(null);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      setUser(null);
    }
  };

  useEffect(() => {
    // Set a timeout to show the footer after 3 seconds
    const timeout = setTimeout(() => {
      setShowFooter(true);
    }, 3000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);


  if (!isLoading) {
    return (
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <CartProvider>
            <NavbarSwitcher refetch={refetch} setRefetch={setRefetch}/>
            <Routes>
              <Route path="/" element={<Landing setRefetch={setRefetch}/>} />
              <Route path="/messages" element={user ? <Messages/> : <Form />} />
              <Route path="/candidature" element={user ? <Candidature/> : <Form />} />
              <Route path="/form" element={<Form />} />
              <Route path="/services" element={<ServicePage />} />
              <Route path="/services/:serviceData" element={<Commande />} />
              <Route path="/produits" element={<ProductPage />} />
              <Route path="/promos" element={<PromoPage />} />
              <Route path="/history" element={user ? <History /> : <Form />} />
              {isPro && <Route path="/dashboard" element={user ? <Dashboard /> : <Form />} />}
              <Route path="/pro" element={<Pro/>}/>
              <Route path="/pro/:creatorName" element={<ProProfile />} />
              <Route path="/postuler" element={<Postuler />} />
              <Route path="/merci" element={user ? <Merci /> : <Form />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/product-info/:productData" element={<ProductInfo />} />
              <Route path="/cart/confirmer" element={<Confirmation />} />
              <Route path="/merciProduit" element={<MerciProduit />} />
              <Route path="/unauthorized" element={<Unauthorized/>}/>
              <Route path="/not-found" element={<NotFound/>}/>
              <Route path="/payment" element={<Payment/>}/>
              <Route path="/failed" element={<Failed/>}/>
              <Route path="/tos" element={<Terms/>}/>
              <Route path="/privacy-policy" element={<Privacy/>}/>
              <Route path="*" element={<NotFound />} />
            </Routes>
            {showFooter && <Footer />} {/* Render the footer after the timeout */}
          </CartProvider>
        </Suspense>
      </Router>
    );
  } else {
    return <LoadingSpinner />;
  }
}

export default App;
