// NavbarSwitcher.jsx
import React, { useState, useEffect } from "react";
import Navbar from "./navbar";


const NavbarSwitcher = ({refetch , setRefetch}) => {
  const [showFixedNavbar, setShowFixedNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      const scrollPosition = window.scrollY;
      setShowFixedNavbar(scrollPosition > 50);
      if (scrollPosition > 50) {
        document.body.classList.add('fixed-navbar-active');
      } else {
        document.body.classList.remove('fixed-navbar-active');
      }
      event.stopPropagation(); // Prevent event bubbling
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  

  return (
  <div>
    <Navbar refetch={refetch} setRefetch={setRefetch} showFixedNavbar={showFixedNavbar}/>
  </div>
    )
};

export default NavbarSwitcher;
